<template>
  <section>
    <v-row>
      <v-col>
        <v-tabs v-model="tab">
          <v-tab v-for="(item, index) in tabs" :key="index">
            {{ item.nombre }}
          </v-tab>
          <v-tabs-slider color="secondary" />
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <br />
            <v-row class="mx-sm-6" no-gutters>
              <v-col cols="12">
                <span class="text-h6 secondary--text">
                  Registros de Compras en Línea
                </span>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="show_date_picker_var"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="month_report_var | moment('DD-MM-YYYY')"
                      label="Fecha *"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @blur="$v.month_report_var.$touch"
                      :error-messages="monthReportError"
                    />
                  </template>
                  <v-date-picker
                    v-model="month_report_var"
                    type="date"
                    color="secondary"
                    locale="es"
                    :min="minimumMonthToReport"
                    :max="moment().format('YYYY-MM-DD')"
                  />
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  label="Área solicitante *"
                  :items="ctl_unidades"
                  item-value="id"
                  item-text="nombre"
                  v-model="area_solic_var"
                  outlined
                  @blur="$v.area_solic_var.$touch"
                  :error-messages="areaSolicError"
                  clearable
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Seleccione el proceso de compra *"
                  outlined
                  dense
                  :items="procesosCompra"
                  item-value="id"
                  item-text="nombre_proceso"
                  v-model="idProceso"
                  @blur="$v.idProceso.$touch"
                  :error-messages="idProcesoError"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Número de factura, CCF o ticket *"
                  v-model="num_doc_var"
                  outlined
                  @blur="$v.num_doc_var.$touch"
                  :error-messages="numDocError"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Nombre de proveedor *"
                  v-model="name_prov_var"
                  outlined
                  @blur="$v.name_prov_var.$touch"
                  :error-messages="nameProvError"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Descripción *"
                  v-model="observacion_var"
                  outlined
                  rows="4"
                  auto-grow
                  clearable
                  @blur="$v.observacion_var.$touch"
                  @input="$v.observacion_var.$touch"
                  :error-messages="observacionError"
                  maxlength="250"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <money-text-field-component
                  label="Monto total *"
                  v-model="monto_total_var"
                  ref="monto_total_var"
                  @blur="$v.monto_total_var.$touch"
                  :error-messages="montoTotalError"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Cantidad *"
                  v-model="cantidad"
                  outlined
                  @blur="$v.cantidad.$touch"
                  :error-messages="cantidadErrors"
                  clearable
                  hide-details
                  type="number"
                />
              </v-col>
            </v-row>
            <br />
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <br />
                <v-autocomplete
                  v-model="form.id_tipo_doc"
                  label="Tipo de documento"
                  outlined
                  :items="catalogoDocumentos"
                  return-object
                  item-text="nombre"
                >
                  <template v-slot:item="{ item }">
                    <v-icon>{{ item.requerido ? "mdi-check" : "" }}</v-icon>
                    &nbsp &nbsp &nbsp
                    {{ item.nombre }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" ms="4">
                <v-file-input
                  v-model="form.doc"
                  label="Subir documentos"
                  multiple
                  outlined
                  accept="application/pdf"
                  show-size
                  counter
                  clearable
                ></v-file-input>
              </v-col>
              <v-col cols="12" ms="4">
                <v-btn @click="agregarDocumento">Agregar documento</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <data-table-component
                  :inhabilitar_paginacion="true"
                  :tiene_paginacion="false"
                  :headers="headers_documentos"
                  :items="documentos"
                >
                  <template v-slot:item.documento="{ item }">
                    {{ item.documento?.name }}
                  </template>
                  <template v-slot:item.tipo="{ item }">
                    {{ item.id_tipo_doc?.nombre }}
                  </template>
                  <template v-slot:item.acciones="{ item }">
                    <v-icon
                      color="error"
                      class="mr-2"
                      v-for="(accion, index) in item.acciones"
                      :key="index"
                      @click="accion.callback(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </data-table-component>
              </v-col>
            </v-row>
            <br />
            <br />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          min-width="30%"
          color="secondary"
          outlined
          @click="$emit('close')"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          min-width="30%"
          color="secondary"
          @click="createRegsFondo"
        >
          Registrar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { convertToFormData } from "@/utils/data";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);
export default {
  components: { DataTableComponent, MoneyTextFieldComponent },
  data: () => ({
    show_date_picker_var: false,
    month_report_var: null,
    num_doc_var: null,
    name_prov_var: null,
    observacion_var: null,
    area_solic_var: null,
    monto_total_var: null,
    cantidad: null,
    idProceso: null,
    tab: 0,
    form: {
      doc: null,
      id_tipo_doc: null,
    },
    tabs: [
      {
        nombre: "Registrar compra",
      },
      {
        nombre: "Subir documentos adjuntos",
      },
    ],
    ctl_unidades: [],
    documentos: [],
    headers_documentos: [
      {
        text: "Documento",
        align: "start",
        value: "documento",
      },
      {
        text: "Tipo de documento",
        align: "center",
        value: "tipo",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
    procesosCompra: [],
    catalogoDocumentos: [],
  }),
  validations: {
    month_report_var: {
      required,
    },
    num_doc_var: {
      required,
    },
    name_prov_var: {
      required,
    },
    observacion_var: {
      required,
      txtArea,
    },
    area_solic_var: {
      required,
    },
    monto_total_var: {
      required,
    },
    cantidad: {
      required,
    },
    idProceso: {
      required,
    },
  },
  computed: {
    idProcesoError() {
      const errors = [];
      if (!this.$v.idProceso.$dirty) return errors;
      !this.$v.idProceso.required &&
        errors.push("El proceso de compra es requerido");
      return errors;
    },
    monthReportError() {
      const errors = [];
      if (!this.$v.month_report_var.$dirty) return errors;
      !this.$v.month_report_var.required &&
        errors.push("El mes a reportar es requerido");
      return errors;
    },
    numDocError() {
      const errors = [];
      if (!this.$v.num_doc_var.$dirty) return errors;
      !this.$v.num_doc_var.required &&
        errors.push("El número de documento es requerido");
      return errors;
    },
    nameProvError() {
      const errors = [];
      if (!this.$v.name_prov_var.$dirty) return errors;
      !this.$v.name_prov_var.required &&
        errors.push("El nombre del proveedor es requerido");
      return errors;
    },
    observacionError() {
      const errors = [];
      if (!this.$v.observacion_var.$dirty) return errors;
      !this.$v.observacion_var.required &&
        errors.push("La descripción es requerida");
      !this.$v.observacion_var.txtArea &&
        errors.push("El motivo posee caracteres especiales no permitidos");
      return errors;
    },
    areaSolicError() {
      const errors = [];
      if (!this.$v.area_solic_var.$dirty) return errors;
      !this.$v.area_solic_var.required &&
        errors.push("El área solicitante es requerida");
      return errors;
    },
    montoRetenidoError() {
      const errors = [];
      if (!this.$v.monto_retenido_var.$dirty) return errors;
      !this.$v.monto_retenido_var.required &&
        errors.push("El monto retenido es requerido");
      return errors;
    },
    montoTotalError() {
      const errors = [];
      if (!this.$v.monto_total_var.$dirty) return errors;
      !this.$v.monto_total_var.required &&
        errors.push("El monto total es requerido");
      return errors;
    },
    cantidadErrors() {
      const errors = [];
      if (!this.$v.cantidad.$dirty) return errors;
      !this.$v.cantidad.required && errors.push("La cantidad es requerida");
      return errors;
    },
    minimumMonthToReport() {
      return this.minDateToReport;
    },
    ...mapState(["selectedUnidad", "userInfo"]),
    docsRequeridosFaltantes() {
      return this.catalogoDocumentos.filter(
        (doc) =>
          !this.documentos.find((d) => d.id_tipo_doc.id === doc.id) &&
          doc.requerido
      );
    },
    docsRequeridosFaltantesTexto() {
      return this.docsRequeridosFaltantes.reduce(
        (prev, next) => `${prev} ${next.nombre}. `,
        ""
      );
    },
  },
  props: {
    id_fondo: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async getUnidades() {
      const response = await this.services.UnidadesServices.getAllUnidades({
        id_institucion: this.selectedUnidad.instituciones.id,
      });
      this.ctl_unidades = response.data;
    },
    async agregarDocumento() {
      if (this.form.doc && this.form.id_tipo_doc) {
        this.documentos.push({
          documento: this.form.doc[0],
          acciones: [
            {
              icon: "mdi-delete",
              tooltip: "Eliminar",
              callback: (item) => {
                this.documentos = this.documentos.filter(
                  (doc) => doc.documento !== item.documento
                );
              },
            },
          ],
          id_tipo_doc: this.form.id_tipo_doc,
        });
        this.form = {
          doc: null,
          id_tipo_doc: null,
        };
        return;
      }
      if (!this.form.doc)
        this.pushAppMessage({
          message: "Debe seleccionar un archivo",
          type: "info",
        });
      if (!this.form.id_tipo_doc)
        this.pushAppMessage({
          message: "Debe seleccionar un tipo de documento",
          type: "info",
        });
    },
    async createRegsFondo() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.pushAppMessage({
          message: "Por favor, complete los campos requeridos",
          show: true,
          type: "warning",
        });
        return false;
      }
      if (this.documentos.length === 0) {
        this.pushAppMessage({
          message: "Por favor, adjunte los documentos requeridos",
          show: true,
          type: "warning",
        });
        this.tab = 1;
        return;
      }

      try {
        await this.services.CompraEnLinea.postAddRegFondo(
          this.id_fondo,
          convertToFormData({
            fecha: this.month_report_var,
            numero_documento: this.num_doc_var,
            nombre_proveedor: this.name_prov_var,
            area_solicitante: this.area_solic_var,
            descripcion: this.observacion_var,
            monto_retenido: Number(this.monto_retenido_var),
            monto_total: Number(this.monto_total_var),
            documentos: this.documentos,
            id_proceso: this.idProceso,
            cantidad: this.cantidad,
          })
        );
        this.pushAppMessage({
          message: "Registro del fondo creado correctamente",
          show: true,
          type: "success",
        });
        this.$emit("actualizar");
      } catch (e) {
        console.log(e);
      }
    },
    async obtenerProcesosCompra() {
      const { data } =
        await this.services.CompraEnLinea.cargarProceosCompraLinea(
          this.id_fondo
        );
      this.procesosCompra = data;
    },
    async obtenerCatalogoDocumentos() {
      const { data } =
        await this.services.CompraEnLinea.cargarTiposDocumentosCompraLinea();
      this.catalogoDocumentos = data;
    },
  },
  async mounted() {
    await this.getUnidades();
    await this.obtenerProcesosCompra();
    await this.obtenerCatalogoDocumentos();
  },
};
</script>
<style>
.fillWidth {
  width: 100%;
  align-items: center;
  margin: 0%;
  padding: 0%;
}
.requerido {
  background-color: greenyellow !important;
}
.opcional {
  background-color: orange !important;
}
</style>
