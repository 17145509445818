<template>
  <section>
    <!-- title -->
    <h5 class="text-h5 secondary--text mb-8">Registro de Compras en Línea</h5>

    <!-- body -->
    <v-row class="mb-4">
      <v-col cols="12" md="5">
        <v-autocomplete
          label="Fondos asignados *"
          :items="ctl_fondos_var"
          item-text="nombre"
          item-value="id"
          v-model="id_fondo_selected_var"
          outlined
          @change="FetchListRegsByFondoFtn"
          clearable
          hide-details
        />
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          block
          :disabled="!id_fondo_selected_var"
          class="text-no-style"
          color="secondary"
          @click="show_modal_add_var = true"
          v-show="!this.diferentUser"
        >
          Añadir
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mb-4" />

    <v-row class="justify-center" v-if="fondo_var">
      <v-col cols="12" sm="4" lg="2" class="text-center">
        <p class="m-0">Total asignado:</p>
        <h6 class="text-h6">
          $
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(fondo_var?.monto_establecido)
          }}
        </h6>
      </v-col>
      <v-col cols="12" sm="4" lg="2" class="text-center">
        <p class="m-0">Total disponible:</p>
        <h6 class="text-h6">
          $
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(fondo_var?.monto_disponible)
          }}
        </h6>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" sm="3" lg="2" v-if="haveRoles(['ROLE_ACL'])">
        <v-btn
          block
          class="text-no-style"
          color="secondary"
          outlined
          @click="confirmSendNotification = true"
        >
          <v-icon color="secondary">mdi-email</v-icon>
          Notificar a UCP
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" lg="2">
        <v-btn
          block
          class="text-no-style"
          color="secondary"
          outlined
          :loading="loadingReporteGeneral"
          @click="downloadReporteGeneral"
        >
          <v-icon color="secondary">mdi-download</v-icon>
          Reporte general
        </v-btn>
      </v-col>

      <v-col cols="12" sm="3" lg="2" v-show="id_fondo_selected_var">
        <v-btn
          block
          class="text-no-style"
          color="secondary"
          outlined
          :loading="loadingReporteFondo"
          @click="downloadReporteFondo"
        >
          <v-icon color="secondary">mdi-download</v-icon>
          Reporte de fondo
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="id_fondo_selected_var">
      <div class="mt-8 d-flex align-center" style="gap: 16px">
        <v-text-field
          v-model="observacion"
          :loading="cargandoRegistros"
          label="Observación"
          class="flex-grow-1 flex-shrink-1 flex-lg-grow-0"
          style="flex-basis: 400px"
          placeholder="Buscar por observación"
          outlined
          clearable
          hide-details
        />
        <v-btn
          @click.stop="buscarFondos"
          x-large
          color="primary"
          :loading="cargandoRegistros"
        >
          Buscar
        </v-btn>
      </div>

      <data-table-component
        :headers="HEADERS_CONST"
        :items="list_regs_var"
        :show_actions="true"
        :total_registros="total_rows_var"
        @paginar="PaginateFtn"
        v-models:pagina="pagination_var.page"
        v-models:select="pagination_var.per_page"
        class="admin-fondos-compra-en-linea-table mt-4"
      >
        <!-- Slot de mes -->
        <template v-slot:[`item.mes_aplicacion`]="{ item }">
          {{ item.mes_aplicacion | moment("MMMM") }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | moment("DD/MM/YYYY") }}
        </template>

        <template v-slot:[`item.estado`]="{ item }">
          <v-chip label>
            {{ item.estadoFondos.nombre }}
          </v-chip>
        </template>
        <template v-slot:[`item.monto`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto)
          }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <div class="d-flex align-center justify-center px-2" style="gap: 8px">
            <DataTableButton
              icon="mdi-eye"
              icon-color="secondary"
              tooltipText="Ver detalles"
              tooltipColor="blueGrayMinsal"
              customClass="mr-0"
              @click="OpenModalDetailFtn(item)"
            />

            <v-tooltip top color="blueGrayMinsal">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="(dialogoDocumentos = true), (idCompra = item.id)"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-file-cabinet</v-icon>
                </v-btn>
              </template>
              <span>Ver documentos</span>
            </v-tooltip>
            

          </div>
        </template>
      </data-table-component>
    </template>

    <v-dialog v-model="dialogoDocumentos">
      <v-card>
        <v-card-actions>
          <v-btn
            block
            @click="(dialogoDocumentos = false), (idCompra = null), (path = ''), ($refs.pdf.limpiar())"
            >Cerrar</v-btn
          >
        </v-card-actions>
        <v-card-title> Documentos de la compra en línea </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-card outline>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      v-for="(documento, index) in documentos"
                      :key="index"
                    >
                      <v-btn
                        block
                        class="secondary py-2 btn-documento"
                        @click.stop="
                          (nombreDocumento =
                            documento.CtlDocumentosCompraLinea.nombre),
                            $refs.pdf.obtenerArchivo(
                              documento.documento,
                              'compraEnLinea'
                            )
                        "
                      >
                        {{ documento.CtlDocumentosCompraLinea.nombre }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="8">
              <v-card outline>
                <v-card-title>
                  {{ nombreDocumento }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12">
                      <pdfModalViejo ref="pdf" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- modal agregar -->
    <v-dialog v-model="show_modal_add_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon d-flex justify-end">
          <v-icon class="secondary--text" @click="CloseModalAddFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <formCompra
            @close="CloseModalAddFtn"
            @actualizar="cerrarYActualizar"
            :id_fondo="id_fondo_selected_var"
            v-if="show_modal_add_var"
          ></formCompra>
          <!-- acciones -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- modal detalle -->
    <v-dialog v-model="show_modal_detail_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="CloseModalDetailFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <!-- registrar fondo -->
          <v-row class="mx-sm-6">
            <v-col cols="12">
              <span class="text-h6 secondary--text">Detalle de fondo</span>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <span v-text="'Fondo asignado'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.FondoCompraLinea?.nombre"
              />
              <span v-text="'Fecha de documento'" />
              <span class="text-h6 secondary--text mb-3">
                {{ moment(detail_regs_var?.fecha).format("DD/MM/YYYY") }}
              </span>
              <span v-text="'Mes reportado'" />
              <span class="text-h6 secondary--text mb-3">
                {{ moment(detail_regs_var?.mes_aplicacion).format("MMMM") }}
              </span>
              <span v-text="'Número de documento'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.numero_documento"
              />
              <span v-text="'Proveedor'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.nombre_proveedor"
              />
              <span v-text="'Descripción'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.observacion"
              />
              <span v-text="'Área solicitante'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.area_solicitante"
              />
              <span v-text="'Monto total'" />
              <span class="text-h6 secondary--text mb-3">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(detail_regs_var?.monto)
                }}
              </span>
            </v-col>
          </v-row>
          <!-- acciones -->
          <v-row
            cols="12"
            class="d-flex flex-column flex-sm-row mx-sm-4 mt-6 mb-3"
          >
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              color="secondary"
              outlined
              @click="CloseModalDetailFtn"
            >
              Cerrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <section class="modales">
      <ConfirmationDialogComponent
        :show="confirmSendNotification"
        btnConfirmar="Enviar notificación"
        btnCancel="Abortar"
        :title="`¿Desea notificar a UCP que ha finalizado los registros del fondo de compra en línea?`"
        @close="confirmSendNotification = false"
        @confirm="sendNotification"
      />

      <ConfirmationDialogComponent
        :show="confirmDeleteGastoFondo"
        btnConfirmar="Eliminar registro"
        btnCancel="Abortar"
        :title="`¿Desea eliminar el registro seleccionado?`"
        :message="`Esta acción es irreversible`"
        @close="
          (confirmDeleteGastoFondo = false), (registro_selected_var = null)
        "
        @confirm="deleteGastoFondo"
      />

      <!-- Modal para editar -->
      <EditarRegistroCompraEnLinea
        :is-open="modalEdicionRegistroAbierta"
        :registro="registro_selected_var"
        :unidades="ctl_unidades"
        @on-visibility-change="manejarVisibilidadModalEdicionRegistro"
        @on-save="confirmarEdicionRegistro"
      />
      <ConfirmationModalComponent
        :is-open="modalConfirmacionEdicionAbierta"
        description="¿Desea continuar con la edición de este registro?"
        :is-loading="edicionCargando"
        @cancel="modalConfirmacionEdicionAbierta = false"
        @confirm="manejarEdicionRegistro"
      />
      <!-- ver observacion -->
      <VisualizarObservacion
        :is-open="modalVerJustificacionAbierta"
        :motivo="justificacionFondoSeleccionado"
        @on-visibility-change="manejarVisibilidadModalVerJustificacion"
      />
    </section>
  </section>
</template>

<script>
// importacion de librerias
import { helpers, required } from "vuelidate/lib/validators";
import EditarRegistroCompraEnLinea from "./components/EditarRegistroCompraEnLinea.vue";
import VisualizarObservacion from "./components/VisualizarObservacion.vue";
import moment from "moment";
import { mapState } from "vuex";
import excel4node from "excel4node";

// importacion de componentes globales
import DataTableComponent from "../../components/DataTableComponent.vue";
import DataTableButton from "../../components/DataTableButton.vue";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import formCompra from "@/views/compraEnLinea/formCompra.vue";
import pdfModalViejo from "@/components/PdfModalClienteViejo.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  components: {
    DataTableComponent,
    DataTableButton,
    MoneyTextFieldComponent,
    ConfirmationDialogComponent,
    EditarRegistroCompraEnLinea,
    ConfirmationModalComponent,
    VisualizarObservacion,
    formCompra,
    pdfModalViejo,
  },

  validations: {
    month_report_var: {
      required,
    },
    num_doc_var: {
      required,
    },
    name_prov_var: {
      required,
    },
    observacion_var: {
      required,
      txtArea,
    },
    area_solic_var: {
      required,
    },
    monto_retenido_var: {
      required,
    },
    monto_total_var: {
      required,
    },
  },

  data: () => ({
    dialogoDocumentos: false,
    idCompra: null,
    path: "",
    documentos: [],
    nombreDocumento: "",
    // constantes
    HEADERS_CONST: [
      {
        align: "center",
        text: "Correlativo",
        value: "correlativo",
      },
      {
        align: "left",
        text: "Fecha de documento",
        value: "fecha",
      },
      {
        align: "left",
        text: "Mes a reportar",
        value: "mes_aplicacion",
      },
      {
        align: "left",
        text: "Proveedor",
        value: "nombre_proveedor",
      },
      {
        align: "left",
        text: "Número documento",
        value: "numero_documento",
      },
      {
        align: "left",
        text: "Observación",
        value: "observacion",
      },
      {
        align: "end",
        text: "Monto total ($)",
        value: "monto",
      },
      // {
      //   align: 'center',
      //   text: 'Estado',
      //   value: 'estado'
      // },
      {
        align: "center",
        text: "Acciones",
        value: "acciones",
        sortable: false,
      },
    ],

    // variables
    show_date_picker_var: false,
    show_modal_add_var: false,

    month_report_var: null,
    num_doc_var: null,
    name_prov_var: null,
    observacion_var: null,
    area_solic_var: null,
    monto_retenido_var: null,
    monto_total_var: null,

    show_modal_detail_var: false,
    id_regs_selected_var: null,
    detail_regs_var: {},
    cantidad: null,

    ctl_fondos_var: [],
    id_fondo_selected_var: null,
    list_regs_var: [],
    pagination_var: {
      page: 1,
      per_page: 10,
    },
    total_rows_var: 0,
    loadingReporteGeneral: false,
    loadingReporteFondo: false,
    loadingSendUCP: false,
    confirmSendNotification: false,
    fondo_var: null,
    ctl_unidades: [],
    confirmDeleteGastoFondo: false,
    registro_selected_var: null,
    diferentUser: null,

    // variables para el modal de carga de excel
    show_upload_excel_file: false,
    file_excel_var: null,
    minDateToReport: null,

    // variables para editar un registro
    modalEdicionRegistroAbierta: false,
    modalConfirmacionEdicionAbierta: false,
    edicionData: null,
    edicionCargando: false,
    modalVerJustificacionAbierta: false,

    cargandoRegistros: false,
    // catalogo de estados
    catalogoEstados: createLoadable([]),
    observacion: "",
    estado: null,
  }),

  computed: {
    ...mapState(["selectedUnidad", "userInfo"]),

    monthReportError() {
      const errors = [];
      if (!this.$v.month_report_var.$dirty) return errors;
      !this.$v.month_report_var.required &&
        errors.push("El mes a reportar es requerido");
      return errors;
    },

    numDocError() {
      const errors = [];
      if (!this.$v.num_doc_var.$dirty) return errors;
      !this.$v.num_doc_var.required &&
        errors.push("El número de documento es requerido");
      return errors;
    },

    nameProvError() {
      const errors = [];
      if (!this.$v.name_prov_var.$dirty) return errors;
      !this.$v.name_prov_var.required &&
        errors.push("El nombre del proveedor es requerido");
      return errors;
    },

    observacionError() {
      const errors = [];
      if (!this.$v.observacion_var.$dirty) return errors;
      !this.$v.observacion_var.required &&
        errors.push("La descripción es requerida");
      !this.$v.observacion_var.txtArea &&
        errors.push("El motivo posee caracteres especiales no permitidos");
      return errors;
    },

    areaSolicError() {
      const errors = [];
      if (!this.$v.area_solic_var.$dirty) return errors;
      !this.$v.area_solic_var.required &&
        errors.push("El área solicitante es requerida");
      return errors;
    },

    montoRetenidoError() {
      const errors = [];
      if (!this.$v.monto_retenido_var.$dirty) return errors;
      !this.$v.monto_retenido_var.required &&
        errors.push("El monto retenido es requerido");
      return errors;
    },

    montoTotalError() {
      const errors = [];
      if (!this.$v.monto_total_var.$dirty) return errors;
      !this.$v.monto_total_var.required &&
        errors.push("El monto total es requerido");
      return errors;
    },

    minimumMonthToReport() {
      return this.minDateToReport;
    },

    justificacionFondoSeleccionado() {
      return this.registro_selected_var
        ? this.registro_selected_var.justificacion
        : "";
    },
  },

  methods: {
    async cerrarYActualizar() {
      await this.CloseModalAddFtn();
      await this.FetchListRegsByFondoFtn();
    },
    CloseModalAddFtn() {
      this.show_modal_add_var = false;
      this.month_report_var = null;
      this.num_doc_var = null;
      this.name_prov_var = null;
      this.observacion_var = null;
      this.area_solic_var = null;

      this.$v.$reset();
    },
    CloseModalDetailFtn() {
      this.show_modal_detail_var = false;
      this.id_regs_selected_var = null;
      this.detail_regs_var = {};
    },

    /**
     *
     * @description: funcion para abrir el modal de detalles del fondo
     * @param {Object} item_param
     */
    OpenModalDetailFtn(item_param) {
      this.id_regs_selected_var = item_param.id;
      this.detail_regs_var = item_param;
      this.show_modal_detail_var = true;
    },
    async PaginateFtn(filters_param) {
      const { cantidad_por_pagina, pagina } = filters_param;

      this.pagination_var.per_page = cantidad_por_pagina;
      this.pagination_var.page = pagina;

      this.FetchListRegsByFondoFtn();
    },

    /**
     *
     * @description: funcion para obtener la lista de registros por fondo ( API )
     * @param {Boolean} init_param
     */
    async FetchListRegsByFondoFtn(init_param = false) {
      try {
        this.cargandoRegistros = true;
        if (this.id_fondo_selected_var) {
          if (init_param) {
            this.pagination_var.page = 1;
            this.pagination_var.per_page = 10;
          }

          const filters_sc_var = {
            page: this.pagination_var.page,
            per_page: this.pagination_var.per_page,
            estado: this.estado,
            observacion: this.observacion,
          };

          const RESP_SC_CONST =
            await this.services.CompraEnLinea.getListRegsFondos(
              this.id_fondo_selected_var,
              filters_sc_var
            );

          if (RESP_SC_CONST.status === 200) {
            const { data, headers } = RESP_SC_CONST;
            this.list_regs_var = data.registros;
            this.fondo_var = data.fondo;
            this.diferentUser = data.diferentUser;

            this.pagination_var.page = Number(headers.page);
            this.pagination_var.per_page = Number(headers.per_page);
            this.total_rows_var = Number(headers.total_rows);
          }

          this.list_regs_var.forEach((item, index) => {
            item.correlativo = index + 1;
          });
        } else {
          this.list_regs_var = [];
          this.pagination_var.page = 1;
          this.pagination_var.per_page = 10;
          this.total_rows_var = 0;
        }
      } catch (error) {
        this.id_fondo_selected_var = null;
      } finally {
        this.cargandoRegistros = false;
      }
    },

    /**
     *
     * @description: funcion para obtener la lista de fondos catalogo ( API )
     */
    async FetchCtlFondosFtn() {
      const RESP_SC_CONST = await this.services.CompraEnLinea.getListFondos({
        pagination: false,
      });

      if (RESP_SC_CONST.status === 200) {
        this.ctl_fondos_var = RESP_SC_CONST.data;
      }
    },

    async downloadReporteGeneral() {
      this.loadingReporteGeneral = true;
      const { data, status } =
        await this.services.CompraEnLinea.getReporteGeneralFondos();
      if (status == 200) {
        const blob = new Blob([data], {
          type: "application/octet-stream",
        });

        const file = new File(
          [blob],
          `reporte-general-fondos${this.moment().format("DD-MM-YYYY")}.xlsx`,
          {
            type: "application/octet-stream",
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
        this.loadingReporteGeneral = false;
      }
    },
    async downloadReporteFondo() {
      this.loadingReporteFondo = true;
      const { data, status } =
        await this.services.CompraEnLinea.getReporteFondo(
          this.id_fondo_selected_var
        );
      if (status == 200) {
        const blob = new Blob([data], {
          type: "application/octet-stream",
        });

        const file = new File(
          [blob],
          `reporte-fondo${this.moment().format("DD-MM-YYYY")}.xlsx`,
          {
            type: "application/octet-stream",
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
        this.loadingReporteFondo = false;
      }
    },
    async sendNotification() {
      const { status } =
        await this.services.CompraEnLinea.enviarNotificacionUCP();

      if (status == 200) {
        this.temporalAlert({
          message: "Notificación enviada a UCP con éxito",
          show: true,
          type: "success",
        });
      }
      this.confirmSendNotification = false;
    },
    async deleteGastoFondo() {
      const { status } = await this.services.CompraEnLinea.deleteGastoFondo(
        this.registro_selected_var.id
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Registro eliminado con éxito",
          type: "success",
        });
        this.FetchListRegsByFondoFtn(true);
      }
      this.confirmDeleteGastoFondo = false;
    },
    showDeleteEvent(data) {
      this.registro_selected_var = data;
      this.confirmDeleteGastoFondo = true;
    },
    // Funciones para carga de archivo excel
    async uploadExcelFile() {
      const formData = new FormData();
      formData.append("file", this.file_excel_var);
      const { status } = await this.services.CompraEnLinea.uploadExcel(
        this.id_fondo_selected_var,
        formData
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Datos registrados con éxito",
          type: "success",
        });
        this.FetchListRegsByFondoFtn(true);
      }

      this.CloseModalUploadExcelFile();
    },
    CloseModalUploadExcelFile() {
      this.file_excel_var = null;
      this.show_upload_excel_file = false;
    },

    async DescargarArchivo() {
      const workbook = new excel4node.Workbook();
      const sheet = workbook.addWorksheet("Formato");
      const columns = [
        { columna: 1, nombre: "fecha" },
        { columna: 2, nombre: "mes" },
        { columna: 3, nombre: "numero_documento" },
        { columna: 4, nombre: "proveedor" },
        { columna: 5, nombre: "descripcion" },
        { columna: 6, nombre: "area_solicitante" },
        { columna: 7, nombre: "monto_retenido" },
        { columna: 8, nombre: "monto_total" },
      ];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < columns.length; i++) {
        sheet.cell(1, columns[i].columna).string(columns[i].nombre);
      }

      const data = await workbook.writeToBuffer();
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "formato-compra-en-linea.xlsx");
      document.body.appendChild(link);
      link.click();
    },

    async getMinDateToReport() {
      // Excepción para poder ingresar en agosto los gastos de mayo 2023, aunque no cumpla con la validación, después deberá funcionar de la manera establecida
      if (moment().format("MM") == "08" && moment().format("YYYY") == "2023") {
        this.minDateToReport = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        return;
      }

      const fechaActual = moment().startOf("month");

      const response = await this.services.CompraEnLinea.postAsueto({
        fecha_inicio: fechaActual,
        dias: 4,
      });
      if (response.status == 200 || response.status == 201) {
        const fechaFinal = moment(response.data.fecha_final).format(
          "YYYY-MM-DD"
        );
        const fechaFinalMoment = moment(fechaFinal);

        if (moment().isBefore(fechaFinalMoment)) {
          this.minDateToReport = moment()
            .subtract(3, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
        } else {
          this.minDateToReport = moment()
            .subtract(2, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
        }
      } else {
        this.temporalAlert({
          show: true,
          message: "No se ha podido obtener la fecha límite para reportar",
          type: "error",
        });
      }
    },

    // Edicion registros
    showEditEvent(data) {
      this.registro_selected_var = { ...data };
      this.modalEdicionRegistroAbierta = true;
    },
    manejarVisibilidadModalEdicionRegistro(visible) {
      this.modalEdicionRegistroAbierta = visible;
    },
    confirmarEdicionRegistro(data) {
      this.edicionData = data;
      this.modalEdicionRegistroAbierta = false;
      this.modalConfirmacionEdicionAbierta = true;
    },
    async manejarEdicionRegistro() {
      try {
        this.edicionCargando = true;
        const idRegistro = this.registro_selected_var.id;
        const { status } = await this.services.CompraEnLinea.editarGastoFondo(
          idRegistro,
          this.edicionData
        );

        if (status == 200) {
          this.pushAppMessage({
            type: "success",
            message: "Registro actualizado correctamente.",
          });
          this.FetchListRegsByFondoFtn(true);
        }
      } catch (error) {
        // this.pushAppMessage({ type: 'error', message: 'Error al actualizar el registro.' });
      } finally {
        this.modalConfirmacionEdicionAbierta = false;
        this.edicionCargando = false;
      }
    },
    manejarVisibilidadModalVerJustificacion(visible) {
      this.modalVerJustificacionAbierta = visible;
    },
    abrirModalVerJustificacion(data) {
      this.registro_selected_var = { ...data };
      this.modalVerJustificacionAbierta = true;
    },
    // Cargar catalogo de estados
    async cargarCatalogoEstados() {
      toggleLoadable(this.catalogoEstados);
      const { data } =
        await this.services.CompraEnLinea.cargarCatalogoEstados();
      setLoadableResponse(this.catalogoEstados, data);
    },
    buscarFondos() {
      this.FetchListRegsByFondoFtn(true);
    },
  },
  watch:{
    idCompra: {
      handler: async function (val) {
        if (val) {
          const { data } = await this.services.CompraEnLinea.cargarDocumentos(
            val
          );
          this.documentos = data;
        }
      },
    },
  },
  created() {
    this.FetchCtlFondosFtn();
    this.getMinDateToReport();
    this.cargarCatalogoEstados();
  },
};
</script>

<style scoped>
.admin-fondos-compra-en-linea-table >>> table thead tr,
.admin-fondos-compra-en-linea-table >>> table tbody tr td {
  background-color: #fff !important;
}

.admin-fondos-compra-en-linea-table >>> table thead tr th:last-child,
.admin-fondos-compra-en-linea-table >>> table tbody tr td:last-child {
  position: sticky;
  right: 0;
}

:deep(.btn-documento) {
  padding: 9px 16px !important;
  max-width: 100%; 
  height: auto !important
}

:deep(.btn-documento .v-btn__content) {
  max-width: 100%;
  word-break: break-word;
  overflow: hidden;
  white-space: normal;
}

</style>
