<template>
    <v-dialog v-model="internalOpen" max-width="700">
    <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative;">
        <div class="pt-4 pb-0" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
            <v-btn 
                style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                class="px-4 py-4"
                @click.stop="cerrarModal" 
                text
                rounded
            >
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
        </div>
        <div class="px-1">
            <h5 
                class="text-h5 text-center font-weight-bold text-uppercase" 
                style="color: var(--v-secondary-base)"
            >
                Motivo:
            </h5>
            <p class="mx-0 my-0 mt-2 text-center">{{ motivo }}</p>
            <v-btn 
                @click.stop="cerrarModal" 
                class="mt-8 d-block mx-auto" 
                color="primary"
            >
                Cerrar
            </v-btn>
        </div>
    </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';


export default {
    name: 'VisualizarObservacion',
    emits: ['on-visibility-change'],
    props: {
        isOpen: { type: Boolean },
        motivo: { type: String },
    },
    data: () =>({
        internalOpen: false,
    }),
    methods: {
        cerrarModal() {
            this.internalOpen = false;
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>